export const DEFAULT_LANGUAGE = 'pl'
const path = new URL(window.location)
const [, lang] = path.pathname.split('/')

export function prefixLanguage(url = '') {
  return 2 >= lang.length && DEFAULT_LANGUAGE !== lang && '' !== lang ? `/${lang}${url}` : url
}

export function addLang(url) {
  return 2 >= lang.length && DEFAULT_LANGUAGE !== lang && '' !== lang ? `${url}/${lang}` : url
}

/**
 * @param {string} path full pathname
 * @returns {srting} pathname without language code
*/
export function removeLanguage(initialPath) {
  const pathElements = initialPath.split('/')
  const [, secondElement, ...rest] = pathElements
  let finalPath = ''
  if (2 === secondElement.length) finalPath = `/${rest.join('/')}`
  else finalPath = initialPath
  return finalPath
}

export function generateHref(obj) {
  const fullPath = `${obj.origin}${obj.lang ? '/' : ''}${`${obj.lang}` || ''}${`${obj.path}` || ''}`
  return fullPath
}
