<template lang="pug">
include ../mixins
+b.ls-card--space_sm.--color_white.--border_sm-adaptive.pos--relative
  ui-preloader(
    :class="{'is-active': isLoad}"
    loader-color='loader-wrapper__bg--accent-1'
    loader-class="loader--variant_absolute"
  )
  bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
    tag="p"
    size="md"
    weight="semi-bold"
  ) {{ commentTitle }}
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    +b.form.ma--bottom_sm(
      :class="{'disabled-light': !canUpdate()}"
    )
      control-textarea-common(
        rules="max:1000"
        class-name="form__textarea form__textarea--light-3 form__textarea--height_md"
        label-class="form__label form__label--accent-1"
        :input-label="_('Коментар')"
        v-model="formdata.comment"
        placeholder=""
        :name="fieldName"
      )

    bem-button.de-text--size_2xs(
      v-if="canUpdate()"
      width="full"
      block="ls-button"
      appearance="yellow"
      :disabled="isLoad"
      @event="validateBeforeSubmit()"
      name='sendBtn'
      key='sendBtn'
    ) {{_('Редагувати')}}
    +b.ma--bottom_sm
    bem-button.de-text--size_2xs(
      width="full"
      block="ls-button"
      appearance="accent-1"
      :disabled="isLoad"
      @event="close()"
      name='closeBtn'
      ley='closeBtn'
    ) {{_('Закрити')}}
</template>

<script>
import {
  updateGradeTestOfStudentResource,
} from '@/js/services/test-module.service'
import SuccessModal from '@/js/components/Modals/SuccessModal'
import FormMixin from '@/js/mixins/form.mixin.js'

export default {
  name: 'show-update-comment-modal',
  mixins: [FormMixin],

  props: {
    testId: {
      type: Number,
    },
    commentTitle: {
      type: String,
      default: '',
    },
    commentValue: {
      type: String,
      default: '',
    },
    fieldName: {
      type: String,
      default: '',
    },
    promise: {},
  },

  data() {
    return {
      formdata: {},
      userType: window.userType,
    }
  },

  computed: {
    isManager() {
      return 'manager' === this.userType
    },
    isTeacher() {
      return 'teacher' === this.userType
    },
  },

  mounted() {
    this.formdata.comment = this.commentValue
  },

  methods: {
    async send() {
      console.log(this.testId)
      console.log(this.formdata.comment)
      console.log(this.fieldName)
      const data = { id: this.testId }
      data[this.fieldName] = this.formdata.comment
      return updateGradeTestOfStudentResource.execute(
        {
          id: this.testId,
        },
        data
      ).then(() => {
        this.$emit('close')
        const text = this._('Коментар успішно оновлено')
        this.promise(this.formdata.comment)
        this.showModal(
          text,
          '',
          3000
        )
      })
    },
    showModal(title, text = '', timeout = '') {
      this.$modal.show(SuccessModal, {
        title,
        text,
        timeout,
      }, {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_sm modal--padding_sm modal--color_white modal--border',
      })
    },
    close() {
      console.log('close')
      this.$emit('close')
    },
    canUpdate() {
      if ('commentOfTeacher' === this.fieldName && this.isTeacher) {
        return true
      }
      if ('commentOfManager' === this.fieldName && this.isManager) {
        return true
      }
      return false
    },
  },
}
</script>
