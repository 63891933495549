import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const CreateConf = () => import('./views/CreateConf')
const UpdateConf = () => import('./views/UpdateConf')
const ListConf = () => import('./views/ListOfConf')

const CreateTest = () => import('./views/CreateTest')
const ListOfTests = () => import('./views/ListOfTests')
const GradeTest = () => import('./views/GradeTest')

const CREATE_CONF_ROUTE_NAME = 'profile:create-configurations'
const UPDATE_CONF_ROUTE_NAME = 'profile:update-configurations'
const LIST_CONF_ROUTE_NAME = 'profile:list-configurations'

const CREATE_TEST_ROUTE_NAME = 'profile:create-test'
const GRADE_TEST_ROUTE_NAME = 'profile:grade-test'
const LIST_OF_TEST_ROUTE_NAME = 'profile:list-of-test'

export function createRoutes(options) {
  return [
    route('configuration/create/',
      CreateConf,
      CREATE_CONF_ROUTE_NAME,
      {
        meta: {
          accessConfig: accessPermission([
            'manager',
          ], options),
        },
      }),
    route('configuration/update/:id/',
      UpdateConf,
      UPDATE_CONF_ROUTE_NAME,
      {
        meta: {
          accessConfig: accessPermission([
            'manager',
          ], options),
        },
      }),
    route(
      'configuration/list/',
      ListConf,
      LIST_CONF_ROUTE_NAME,
      {
        meta: {
          accessConfig: accessPermission([
            'manager',
          ], options),
        },
        // children: [

        // ],
      }
    ),
    route('test/create/',
      CreateTest,
      CREATE_TEST_ROUTE_NAME,
      {
        meta: {
          accessConfig: accessPermission([
            'manager',
            // 'teacher',
          ], options),
        },
      }),
    route('test/grade/:id/',
      GradeTest,
      GRADE_TEST_ROUTE_NAME,
      {
        meta: {
          accessConfig: accessPermission([
            'manager',
            'teacher',
          ], options),
        },
      }),
    route('test/list/',
      ListOfTests,
      LIST_OF_TEST_ROUTE_NAME,
      {
        meta: {
          accessConfig: accessPermission([
            'manager',
            'teacher',
          ], options),
        },
      }),
  ]
}
