<template lang="pug">

  slick(
    ref="slick"
    :options="options"
  )
    slot

</template>

<script>
import Slick from 'vue-slick'

export default {
  name: 'ui-slider',
  components: {
    Slick,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
}
</script>
