<template lang="pug">
  include ../mixins
  div
    ui-preloader(
      :class="{'is-active': isLoad}"
      loader-color="loader-wrapper__bg--accent-1"
      loader-class="loader--variant_absolute"
    )
    bem-text.ma--top_3xs-xxl.ma--bottom_3sm(
      tag="p"
      color="white"
      size="md"
      weight="semi-bold"
    ) {{_("Форма зв'язку")}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
      @keyup.enter="validateBeforeSubmit"
    )
      +b.form--full-width.ma--bottom_sm.pos--relative(v-for="input in formModel")
        template(v-if="input.view === 'input'")
          component(
            :is="input.component"
            :rules="input.rules"
            :type="input.type"
            :inputmode="input.type"
            :label-class="input.labelClass"
            :class-name="input.className"
            :placeholder="input.placeholder"
            v-model="formdata[input.model]"
            :required="input.required"
            :name="input.name"
            :mask="input.mask"
            :options="input.options"
            @keyup.enter="validateBeforeSubmit"
          )
        template(v-if="input.view === 'phone'")
          validation-provider(
            v-slot="{ errors }"
            :name="input.name"
            :rules="{ required: true, phone_is_valid: [phoneIsValid] }"
          )
            vue-phone-number-input(
              v-model="formdata[input.model]"
              :name="input.name"
              default-country-code='PL'
              @update="validatePhone"
              :translations="translations"
            )
            +b.control-hint.--variant_error(v-if="errors[0]") {{ errors[0] }}
        //- div(
        //-   v-if="input.view === 'multiselect'"
        //-   v-tooltip.hover.ios="input.tooltip && options[input.options].length === 0 ? {content: input.tooltip } : { visible: false }")
        //-   component(
        //-     is="control-multiselect-pure"
        //-     :rules="input.rules"
        //-     :options="options[input.options]"
        //-     :class="input.className"
        //-     :placeholder="input.placeholder"
        //-     :track-by="input.trackBy"
        //-     v-model="formdata[input.model]"
        //-     :label="input.label"
        //-     :name="input.name"
        //-     :disabled="options[input.options].length === 0"
        //-     @input="input.handler"
        //-   )
      privacy-rules.ma.ma--bottom_sm(
        :rules-terms="objectItem.rules"
        :privacy="objectItem.privacy"
      )
      bem-button(
        width="full"
        block="ls-button"
        appearance="yellow-arrow"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('відіслати')}}
</template>

<script>
import { mapState } from 'vuex'
import FormMixin from '@/js/mixins/form.mixin.js'
import { contactResource } from '@/js/services/common.service.js'
// import {
//   schoolsCitiesResource,
// } from '@/js/services/additional.service.js'

export default {
  name: 'contact-form-modal',
  mixins: [FormMixin],
  components: {
    'privacy-rules': () => import('@/js/components/Forms/PrivacyRules'),
  },
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        name: '',
        phone: '',
        // email: '',
        // direction: '',
        // typeDirection: '',
        school: '',
        isLoad: false,
      },
      formModel: [
        {
          component: 'control-input-pure',
          view: 'input',
          rules: 'alpha_spaces|required',
          type: 'text',
          className: 'form__input form__input--white',
          placeholder: this._('Вашe ім’я *'),
          model: 'name',
          required: true,
          name: 'name',
          options: null,
        },
        {
          component: 'vue-phone-number-input',
          view: 'phone',
          rules: 'required|min:10',
          type: 'number',
          className: 'form__input form__input--white',
          placeholder: this._('Ваш телефон *'),
          model: 'phone',
          required: true,
          name: 'phone',
          options: {
            color: '#0000FF',
            'valid-color': '#FFFF00',
          },
        },
        // {
        //   component: 'control-tel-input-pure',
        //   view: 'input',
        //   rules: 'required|min:10',
        //   type: 'number',
        //   className: 'form__input form__input--white',
        //   placeholder: this._('Ваш телефон *'),
        //   model: 'phone',
        //   required: true,
        //   name: 'phone',
        //   options: null,
        // },
        // {
        //   component: 'control-input-pure',
        //   view: 'input',
        //   rules: 'email|required',
        //   type: 'email',
        //   labelClass: 'form__label form__label--white',
        //   className: 'form__input form__input--white',
        //   placeholder: this._('Ваш e-mail *'),
        //   model: 'email',
        //   required: true,
        //   name: 'email',
        //   options: null,
        // },
        // {
        //   component: 'control-multiselect-pure',
        //   view: 'multiselect',
        //   rules: 'required',
        //   className: 'common-multiselect common-multiselect--white',
        //   placeholder: this._('Направление *'),
        //   model: 'direction',
        //   required: true,
        //   options: 'directionsList',
        //   trackBy: 'id',
        //   label: 'title',
        //   name: 'direction',
        //   handler: this.getDirectionsTypes,
        // },
        // {
        //   component: 'control-multiselect-pure',
        //   view: 'multiselect',
        //   rules: 'required',
        //   className: 'common-multiselect common-multiselect--white',
        //   placeholder: this._('Тип направления *'),
        //   model: 'typeDirection',
        //   required: true,
        //   options: 'directionTypesList',
        //   trackBy: 'id',
        //   label: 'title',
        //   name: 'typeDirection',
        //   tooltip: this._('Сначала выберите направление'),
        // },
        // {
        //   component: 'control-multiselect-pure',
        //   view: 'multiselect',
        //   rules: 'required',
        //   className: 'common-multiselect common-multiselect--white',
        //   placeholder: this._('Школа *'),
        //   model: 'school',
        //   required: true,
        //   options: 'schoolsList',
        //   trackBy: 'id',
        //   label: 'title',
        //   name: 'school',
        // },
      ],
      options: {
        // directionsList: [],
        // directionTypesList: [],
        // schoolsList: [],
      },
      phoneIsValid: false,
      translations: {
        countrySelectorLabel: this._('Оберіть країну'),
        countrySelectorError: this._('Оберіть країну'),
        phoneNumberLabel: this._('Ваш телефон *'),
        example: this._('Приклад вводу :'),
      },
    }
  },

  computed: {
    ...mapState('users/auth', [
      'user',
    ]),
  },

  watch: {
    // 'formdata.direction': {
    //   handler() {
    //     this.formdata.typeDirection = ''
    //   },
    // },
    user: {
      immediate: true,
      deep: true,
      handler(nval) {
        if (window.isAuthenticated) {
          this.setUserInfo(nval)
        }
      },
    },
  },
  mounted() {
    // this.getDirections()
    // this.getSchools()
  },

  methods: {
    validatePhone(state) {
      this.phoneIsValid = state.isValid
    },

    setUserInfo(data) {
      const { info } = data
      this.formdata.phone = info.phone
      if ('company' === info.typeUser) {
        this.formdata.name = info.firstNameContact
      } else {
        this.formdata.name = info.firstName
      }
    },

    async send(data) {
      this.isLoad = true
      const copyData = await this.prepareData({ ...data })

      if (this.objectItem.directionTypeId) {
        copyData.typeDirection = this.objectItem.directionTypeId
      }

      return contactResource.execute({}, copyData)
        .then(res => {
          this.isLoad = false
          this.handleResponse({ res, modal: true })
        })
    },
    // getDirections() {
    //   return directionResource.execute({}, {})
    //     .then(({ data: { item: { items } } }) => {
    //       this.options.directionsList = items
    //     })
    // },
    // getDirectionsTypes() {
    //   const id = valueFromSelect(this.formdata.direction, 'id')
    //   return directionTypesResource.execute({}, { idDirection: id })
    //     .then(({ data: { item: { items } } }) => {
    //       this.options.directionTypesList = items
    //     })
    // },

    // getSchools() {
    //   return schoolsCitiesResource.execute({}, {})
    //     .then(({ data: { item: { items } } }) => {
    //       this.options.schoolsList = items
    //     })
    // },
  },
}
</script>
