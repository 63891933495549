import Vue from 'vue'

Vue.directive('accordion', {
  bind: node => {
    const timeout = 200
    const triggers = node.querySelectorAll('.js-accordion-trigger')
    const xl = 1200

    triggers.forEach(el => {
      if (el.getAttribute('id')) {
        el.addEventListener('click', () => {
          const isMobile = window.innerWidth < xl
          const header = document.querySelector('.js-header .header-wrapper-mobile')

          triggers.forEach(elem => {
            if (elem !== el) {
              const id = elem.getAttribute('id')
              // console.log('elem id', id)
              const content = document.getElementById(`accord_content_${id}`)
              // console.log('content1', content)
              // console.log(`accord_content_${id}`)
              // const content = document.getElementById(id)
              // const content = document.getElementById(`[id=${id}]`)
              if (content) {
                content.classList.remove('is-active')
              }
              // elem.closest('.js-accordion-item').classList.remove('is-active')
            }
          })

          const id = el.getAttribute('id')
          const content = document.getElementById(`accord_content_${id}`)
          // console.log('content2', content)
          content.classList.toggle('is-active')
          // el.classList.toggle('is-active')
          // el.closest('.js-accordion-item').classList.toggle('is-active')

          setTimeout(() => {
            const headerHeight = isMobile ? header.clientHeight : 0
            const topPos = el.getBoundingClientRect().top + window.scrollY - 10 - headerHeight

            window.scrollTo({
              top: topPos,
              behavior: 'smooth',
            })
          }, timeout)
        })
      }
    })
  },
})
