export default {
  place: {
    pl: ['miejsce', 'miejsca', 'miejsc'],
    uk: ['місце', 'місця', 'місць'],
    en: ['place', 'places', 'places'],
  },
  month: {
    pl: ['miesiąc', 'miesiąca', 'miesięcy'],
    uk: ['місяць', 'місяці', 'місяців'],
    en: ['month', 'months', 'months'],
  },
  week: {
    pl: ['tydzień', 'tygodnie', 'tygodnie'],
    uk: ['тиждень', 'тижні', 'тижнів'],
    en: ['week', 'weeks', 'weeks'],
  },
}
