<template lang="pug">
  include ../../mixins
  +b.t-wrapper
    +b.t-container
      +b.t-card.--variant_1
        +e.content
          validation-observer(
            tag="form"
            ref="observer"
            v-slot="{ errors }"
          )
            ui-preloader(
              :class="{'is-active': isLoading}"
              loader-color='loader-wrapper__bg--accent-1'
            )

            +b.ds-panel.--space_sm.--space_2sm-xl
              +e.element.--offset_bottom
                slot(name="heading")

            +b.ds-panel.--space_sm.--space_2sm-xl
              +e.element.--offset_bottom
                slot(name="subtitle")

            +b.ds-panel.--space_sm.--space_2sm-xl
              +e.element.--offset_bottom
                +b.t-card.--bg_variant-1
                  +b.g-row.--appearance_spaced.--space_sm.--space_2sm-xl
                    +b.g-cell.g-cols--12.--6-sm.--4-xl
                      +b.form
                        control-input-common(
                          rules="alpha_spaces|required"
                          type="text"
                          class-name="form__input form__input--white"
                          label-class="form__label form__label--accent-1"
                          :input-label="labels.firstName"
                          v-model="preRequestFormdata.firstName"
                          :required="true"
                          name="firstName"
                        )
                    +b.g-cell.g-cols--12.--6-sm.--4-xl
                      +b.form
                        control-input-common(
                          rules="alpha_spaces|required"
                          type="text"
                          class-name="form__input form__input--white"
                          label-class="form__label form__label--accent-1"
                          :input-label="labels.lastName"
                          v-model="preRequestFormdata.lastName"
                          :required="true"
                          name="lastName"
                        )
                    +b.g-cell.g-cols--12.--6-sm.--4-xl
                      +b.form--full-width
                        validation-provider(
                          v-slot="{ errors }"
                          name="phone"
                          :rules="{ required: true, phone_is_valid: [phoneIsValid] }"
                        )
                          +b.LABEL.form__label.form__label--accent-1
                            span {{ labels.phone }}
                          vue-phone-number-input(
                            v-model="preRequestFormdata.phone"
                            name="phone"
                            default-country-code='PL'
                            @update="validatePhone"
                            :translations="translations"
                          )
                          +b.control-descriptor__errors(v-if="errors[0]")
                            SPAN.control-hint.control-hint--variant_1 {{ errors[0] }}
                          //- +b.control-hint.--variant_error(v-if="errors[0]")
                        //- control-tel-input-common(
                        //-   rules="required"
                        //-   type="tel"
                        //-   class-name="form__input form__input--white"
                        //-   label-class="form__label form__label--accent-1"
                        //-   :input-label="labels.phone"
                        //-   v-model="preRequestFormdata.phone"
                        //-   :required="true"
                        //-   name="phone"
                        //- )

                    +b.g-cell.g-cols--12.--6-sm.--4-xl
                      +b.form
                        control-input-common(
                          rules="email"
                          type="email"
                          class-name="form__input form__input--white"
                          label-class="form__label form__label--accent-1"
                          :input-label="labels.email"
                          v-model="preRequestFormdata.email"
                          :required="true"
                          name="email"
                        )

                    +b.g-cell.g-cols--12.--6-sm.--4-xl(v-if="needDisplayContactName")
                      +b.form
                        control-input-common(
                          rules="alpha_spaces"
                          type="text"
                          class-name="form__input form__input--white"
                          label-class="form__label form__label--accent-1"
                          :input-label="labels.contactName"
                          v-model="preRequestFormdata.contactName"
                          name="contactName"
                        )
                    +b.g-cell.g-cols--12.--6-sm.--4-xl
                      +b.form
                        control-input-common(
                          rules="alpha_spaces"
                          type="text"
                          class-name="form__input form__input--white"
                          label-class="form__label form__label--accent-1"
                          name="city"
                          v-model="formdata.city"
                          :input-label="labels.city"
                        )
                    +b.g-cell.g-cols--12.--6-sm.--4-xl
                      +b.form
                        control-input-common(
                          :mask="{ mask: '99.99.9999', placeholder: '.', clearMaskOnLostFocus: true, showMaskOnHover: false }"
                          rules="required"
                          type="tel"
                          class-name="form__input form__input--white"
                          label-class="form__label form__label--accent-1"
                          name="birthday"
                          v-model="preRequestFormdata.birthday"
                          :input-label="labels.birthday"
                        )
                    +b.g-cell.g-cols--12.--6-sm.--4-xl
                      +b.form
                        control-multiselect-common(
                          v-model="preRequestFormdata.source"
                          class="common-multiselect common-multiselect--white"
                          name="source"
                          rules="required"
                          label-class="form__label form__label--accent-1"
                          label="label"
                          placeholder=""
                          :hide-placeholder="true"
                          :options="sources"
                          :input-label="labels.source"
                          :required="true"
                        )

            +b.ds-panel.--space_sm.--space_2sm-xl
              +e.element.--offset_bottom
                slot(name="description_2")

          +b.ds-panel.--space_sm.--space_2sm-xl(v-if="choices.dates.length")
            +e.element.--offset_top
              interview-calendar(
                :dates="choices.dates"
                :time-slots="choices.timeSlots"
                @select:date="getTimeSlots"
                @select:time="setTimeSlot"
              )
          +b.ds-panel.--space_sm
            +e.element.--offset_top
              +b.t-card.--bg_variant-1(v-if="!choices.dates.length")
                +b.ds-panel.--space_sm.--space_2sm-xl
                  +e.element.--offset_bottom
                    +b.P.ls-text.--size_2xs-v2.--weight_semi-bold.--height_lg {{ _('По выбранным параметрам нет свободных мест для записи на онлайн тестирование') }}
                slot(name="description_3")

              +b.ds-panel.--space_sm
                +e.element.--offset_vertical
                  +b.g-row.--appearance_spaced.--space_sm
                    +b.g-cell.g-cols--12.--8-xl
                      slot(name="description_1")
                    +b.g-cell.g-cols--12.--4-xl
                      +b.BUTTON.ls-button.--appearance_yellow.--width_full.--variant_11(
                        @click.prevent="prepareNonInterviewData"
                      ) {{ _('Відправити граматичний тест') }}
          +b.ds-panel.--space_sm.--space_md-xl
            +e.element.--offset_top
              +b.g-row.--appearance_spaced.--space_sm.--align_center
                +b.g-cell.g-cols--12.--8-xl
                  +b.P.ls-text.--size_2xs.--height_lg.--color_accent-1 {{ _("Відправляючи форму, Ви погоджуєтесь із") }}
                    |&nbsp;
                    +b.A.ls-link.--variant_underline.--size_2xs.ls-text.--weight_semi-bold.--color_accent-1(
                      :href="privacyPolicyUrl"
                      target='_blank'
                    ) {{ _("Політичною конфіденційності") }}
                    |&nbsp;
                    span {{ _("та") }}
                    |&nbsp;
                    +b.A.ls-link.--variant_underline.--size_2xs.ls-text.--weight_semi-bold.--color_accent-1(
                      :href="termsConditionsUrl"
                      target='_blank'
                    ) {{ _("Правилами сайту") }}
                +b.g-cell.g-cols--12.--4-xl(v-if="choices.dates.length")
                  +b.BUTTON.ls-button.--appearance_yellow.--width_full.--variant_11(
                    @click.prevent="prepareInterviewData"
                  ) {{ _('Відіслати') }}
</template>

<script>
import FormMixin from '@/js/mixins/form.mixin.js'
import SuccessModal from '@/js/components/Modals/SuccessModal'
import {
  quizRequestCreate,
  quizInterviewResource,
  quizNonInterviewResource,
  scheduleListResource,
} from '@/js/services/taketest.service'

const quizAge = localStorage.getItem('quiz_age') || '{}'
const today = new Date()

today.setHours(0, 0, 0, 0)

export default {
  components: {
    'interview-calendar': () => import('./Calendar'),
  },

  mixins: [
    FormMixin,
  ],

  props: {
    privacyPolicyUrl: {
      type: String,
    },
    termsConditionsUrl: {
      type: String,
    },
    defaultSchoolId: {
      type: String,
    },
    sources: {
      type: Array,
    },
  },

  data() {
    return {
      isLoading: false,
      labels: {
        firstName: this._('Имя'),
        lastName: this._('Фамилия'),
        phone: this._('Номер телефона*'),
        email: this._('E-mail'),
        contactName: this._('Контактное лицо'),
        city: this._('Ваш город'),
        birthday: this._('Дата рождения'),
        source: this._('Откуда о нас узнали?'),
      },
      preRequestFormdata: {
        age: JSON.parse(quizAge),
      },
      formdata: {
        isOnline: true,
        slot: null,
      },
      choices: {
        dates: [],
        timeSlots: [],
      },
      phoneIsValid: false,
      translations: {
        countrySelectorLabel: this._('Оберіть країну'),
        countrySelectorError: 'Оберіть країну',
        phoneNumberLabel: 'Ваш телефон *',
        example: 'Приклад:',
      },
    }
  },

  created() {
    this.fetchDates()
  },

  computed: {
    needDisplayContactName() {
      const { age } = this.preRequestFormdata.age

      return 'child' === age || 'teenager' === age
    },
  },

  methods: {
    validatePhone(state) {
      this.phoneIsValid = state.isValid
    },

    updateDate(data) {
      this.preRequestFormdata.birthday = data
    },

    async fetchDates() {
      this.$nextTick(() => {
        const formdata = {
          isOnline: Number(Boolean(this.formdata.isOnline)),
          ageId: this.preRequestFormdata.age.id,
          schoolId: this.defaultSchoolId,
        }

        scheduleListResource.execute({}, formdata).then(res => {
          const { data: { items } } = res

          this.choices.dates = items
        })
      })
    },

    getTimeSlots(date) {
      this.choices.timeSlots = this.choices.dates.find(d => d.date === date).slots
    },

    setTimeSlot(slot) {
      this.formdata.slot = slot

      this.prepareInterviewData()
    },

    async prepareNonInterviewData() {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        this.scrollToError()
        return
      }

      if (!this.formdata.preRequest) {
        await this.submit(isValid, this.send)
      }

      this.submitNonInterview()
    },

    async prepareInterviewData() {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        this.scrollToError()
        return
      }

      if (this.choices.dates.length) {
        if (this.formdata.slot) {
          if (!this.formdata.preRequest) {
            await this.submit(isValid, this.send)
          }

          this.submitInterview()
        } else {
          const title = this._('Выберите время интервью')

          this.openCommonModal(title)
        }
      } else {
        const title = this._('Извините, для выбранной школы нет доступных дат для интервью')

        this.openCommonModal(title)
      }
    },

    async send() {
      this.isLoading = true

      const formdata = JSON.parse(JSON.stringify(this.preRequestFormdata))

      formdata.age = formdata.age.id
      formdata.source = formdata.source.value

      if (!formdata.email) {
        delete formdata.email
      }

      return quizRequestCreate.execute({}, formdata).then(res => {
        const { data: { item: { id } } } = res

        this.formdata.preRequest = id
      }).finally(() => {
        this.isLoading = false
      })
    },

    submitInterview() {
      this.isLoading = true

      const formdata = JSON.parse(JSON.stringify(this.formdata))

      quizInterviewResource.execute({}, formdata).then(() => {
        this.openSuccessModal()
        this.clearStorage()
      }).catch(() => {
        this.openErrorModal()
      }).finally(() => {
        this.isLoading = false
      })
    },

    submitNonInterview() {
      this.isLoading = true

      const formdata = {
        preRequestId: this.formdata.preRequest,
        city: this.formdata.city,
      }

      quizNonInterviewResource.execute({}, formdata).then(() => {
        this.openSuccessModal()
        this.clearStorage()
      }).catch(() => {
        this.openErrorModal()
      }).finally(() => {
        this.isLoading = false
      })
    },

    clearStorage() {
      localStorage.removeItem('quiz_age')
      localStorage.removeItem('quiz_section')
    },

    openSuccessModal() {
      this.$modal.show(SuccessModal, {
        title: this._('Дякуємо, тест успішно відправлено'),
        text: this._('Мы свяжемся с Вами в ближайшее время'),
      }, {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_sm modal--padding_2sm modal--color_white modal--border',
      }, {
        'before-close': () => {
          window.location = window.indexPageUrl
        },
      })
    },

    openErrorModal() {
      this.$modal.show(SuccessModal, {
        title: this._('Непредвиденная ошибка'),
      }, {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_sm modal--padding_2sm modal--color_white modal--border',
      })
    },

    openCommonModal(title) {
      this.$modal.show(SuccessModal, { title }, {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_sm modal--padding_2sm modal--color_white modal--border',
      })
    },

    scrollToError() {
      const errArray = document.getElementsByClassName('control-hint')
      if (errArray.length) {
        errArray[0].scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>
